import React, { Component, ReactNode } from 'react';
import { DefaultButton, PrimaryButton, Modal } from '@fluentui/react';
import {AppState, UserType} from '../../../App';
import DataForm, { DataFormProps } from '../../ApplicationData/Pages/ApplicationData';
import styles from '../css/Application.module.css';
import { Icon } from '@fluentui/react/lib/Icon';
import Config from '../../../core/Config';
import { RouteComponent } from '../../../core/parts/NavWithRouter';
const Checked = () => <Icon  iconName="CheckboxComposite"  style={{fontSize: '25px',paddingRight:' 5px'}}/>;
const Unchecked = () => <Icon  iconName="Checkbox"  style={{fontSize: '25px',paddingRight:' 5px'}}/>;


type ApplicationProps = Readonly <{
    items: AppState['applications'],
    config: typeof Config,
    user: UserType,
    users: AppState['users'],
    onSave: (item: any) =>  (() => void),
    router: {
        location: any,
        navigate: any,
        params: any
    }
}>
interface ApplicationState {
    new: boolean,
    item: {
        id: number | string,
        customer: string,
        data: Required<DataFormProps>['data'],
        email: string,
        forms: string,
        doctor: string,
        status: string,
    }
    showModal: boolean,
}

class Application extends Component<ApplicationProps, ApplicationState> {

    props: ApplicationProps;
    state: ApplicationState = {
        new: true,
        item: {
            customer: '',
            id: '',
            data: {
                addressCity: '',
                addressLine1: '',
                addressLine2: '',
                addressPLZ: '',
                berufsschule: false,
                berufsschuleTime: '',
                birthcountry: '',
                birthday: '',
                birthname: '',
                birthplace: '',
                bloodCirculation: '',
                disease: '',
                doctorAddressCity: '',
                doctorAddressLine1: '',
                doctorAddressLine2: '',
                doctorAddressPLZ: '',
                doctorEmail: '',
                doctorForename: '',
                doctorName: '',
                doctorPhoneNumber: '',
                doctorTitle: '',
                education: '',
                email: '',
                employees: '',
                employementStart: '',
                employementType: '',
                employer: '',
                employerType: '',
                fachhochschule: false,
                fachhochschuleTime: '',
                fachoberschule: false,
                fachoberschuleTime: '',
                fachschule: false,
                fachschuleTime: '',
                feeling: '',
                footOperation: '',
                footOperationReason: '',
                foreignCountry: '',
                forename: '',
                gesamtschule: false,
                gesamtschuleTime: '',
                graduation: '',
                gymnasium: false,
                gymnasiumTime: '',
                handicap: '',
                GesundheitProbleme: '',
                healthProblems: '',
                healthProblemsDesc: '',
                handicapType: '',
                handicapGrad: '',
                handicapMZ: '',
                handicapSince: '',
                Schwerb: '',
                MDK_AA_Accredited: '',
                MDK_AA_AccreditedInstitution: '',
                arbMedVU: '',
                arbMedVUGrund: '',
                BetriebsarztVorh: '',
                Betriebsarzt: '',
                BetriebsarztWeitergabe: '',
                hauptschule: false,
                hauptschuleTime: '',
                healthInsuranceAddressCity: '',
                healthInsuranceAddressLine1: '',
                healthInsuranceAddressLine2: '',
                healthInsuranceAddressPLZ: '',
                healthInsuranceName: '',
                healthInsurancePhoneNumber: '',
                healthInsuranceType: '',
                hochschule: false,
                hochschuleTime: '',
                illnessAccredited: 'Nein',
                illnessApplication: '',
                illnessApplicationInstitution: '',
                illnessInstitution: '',
                illnessReference: '',
                illnessType: '',
                investigation: '',
                jobDescription: '',
                maritalStatus: '',
                maxWeights: '',
                mdk: '',
                name: '',
                nationality: '',
                parentServices: '',
                parentServicesDate: '',
                parentServicesInsurance: '',
                parentServicesReference: '',
                pensionHolder: '',
                pensionNumber: '',
                phoneNumber: '',
                position: '',
                profession: '',
                realschule: false,
                realschuleTime: '',
                rehab: '',
                rehabEnd: '',
                rehabInstitution: '',
                rehabReference: '',
                rehabStart: '',
                retraining: '',
                retrainingInstitution: '',
                retrainingLocation: '',
                retrainingReason: '',
                retrainingReference: '',
                retrainingSuccess: '',
                retrainingTime: '',
                retrainingType: '',
                courseParticipation:'',
                courseParticipationSuccess:'',
                courseParticipationTypeAndPeriod:'',
                knowledgeOfForeignLanguages:'',
                knowledgeOfForeignLanguagesLevel:'',
                jobSeeking:'',
                jobSeekingAt:'',
                jobSeekingAA:'',
                jobless:'',
                joblessAt:'',
                joblessAA:'',
                jobTime:'',
                jobEndReason:'',
                jobRunning:'Ja',
                jobRunningWish:'',
                sex: '',
                sitting: '',
                standing: '',
                bentover: '',
                armsAboveChest: '',
                kneeling: '',
                onLadders: '',
                liftingWeights: '',
                offtenWeights: '',
                technicalLiftingAids: '',
                technicalLiftingAidsText: '',
                LiftComment: '',
                Ganztagsarbeit: '',
                Teilzeitarbeit: '',
                "andere Arbeitszeitmodelle": '',
                "regelmäßige Frühschicht / Spätschicht": '',
                "regelmäßige Dreischicht": '',
                "andere-Arbeitszeitmodelle": '',
                "andere-ArbeitszeitmodelleText": '',
                study: '',
                sugar: '',
                sugarUlcer: '',
                temporaryEmployement: '',
                temporaryEmployementEnd: '',
                terminatedEmployement: '',
                terminatedEmployementEnd: '',
                title: '',
                vocationalTraining: '',
                vocationalTrainingExam: '',
                vocationalTrainingTime: '',
                walking: '',
                workingTime: '',
                'atemwegsreizende-Stoffe-Text': '',
                'hautreizende-Stoffe-Text': '',
                'Gase-Gerüche-Dämpfe': '',
                'Gase-Gerüche-Dämpfe-Text': '',
                'Rauch': '',
                furtherRequest: '',
                furtherRequestText: '',
                furtherRequest2: '',
                furtherRequest2Text: '',
            },
            email: '',
            forms: '',
            doctor: '',
            status: 'open',
        },
        showModal: false,
    };

    componentDidMount(){
        this._selectItem();
    }

    componentDidUpdate(prevProps: any) {
        let {id} = this.props.router.params;
        if(id !== prevProps.router.params.id || this.props.items !== prevProps.items){
            this._selectItem();
        }
    }

    /**
     *
     * @param {string} label
     * @param {any} value
     * @param {any} search
     * @constructor
     */
    CheckorUncheck(label: string, value: any, search: any, state = false) {

        let holder: ReactNode[]  = [];
        if(state === true) {
            if (value > search) {
                holder.push(<>
                    <div style={{display:"flex",alignItems:"center"}}>
                        <Checked/>
                        <span>{label}</span>
                    </div>
                </>)
            } else {
                holder.push(<>
                    <div style={{display:"flex",alignItems:"center"}}>
                        <Unchecked/>
                        <span>{label}</span>
                    </div>
                </>)
            }
        } else {
                if (value === search) {
                    holder.push(<>
                        <div style={{display:"flex",alignItems:"center"}}>
                            <Checked/>
                            <span>{label}</span>
                        </div>
                    </>)
                } else {
            holder.push(<>
                <div style={{display:"flex",alignItems:"center"}}>
                    <Unchecked/>
                    <span>{label}</span>
                </div>
            </>)
        }
        }

        return holder;


    }

    render() {
        //const config = this.props.config;
        let {id} = this.props.router.params;
        let navigate = this.props.router.navigate;
        const { customer, data, email, forms, doctor, status } = this.state.item;
        const invalidInput = false;
        console.log(email)
        let templates = {
            subjectFirstMail: 'Ihr Antrag beim Rententräger: weitere Schritte',
            bodyFirstMail: `Sehr geehrte:r ${data!.forename + ' ' + data!.name},

für die Beantragung von Einlagen in Arbeitsschutzschuhe über den Rententräger sind folgende Schritte notwendig:

1.  Bitte legen Sie Ihrem Arbeitgeber das beigefügte Formular G0134 vor.Hier bestätigt dieser, dass Sie Sicherheitsschuhe bei der Arbeit tragen müssen (Wichtig: Stempel des Unternehmens nicht vergessen!)
2.  Bitte lassen Sie sich von Ihrem Orthopäden Einlagen für den Freizeitbereich verschreiben (siehe Rezeptvorschlag).
3.  Bitte unterschreiben Sie die beigefügten Managementaufträge.

Sobald Ihnen diese Unterlagen vorliegen, vereinbaren Sie bitte einen Termin in einer unserer Filialen.
 Wir bereiten die weiteren Formulare anhand der vorliegenden Daten zur Unterzeichnung vor.

Bei Rückfragen stehen wir Ihnen gern zur Verfügung.

Mit freundlichen Grüßen
`,
            subjectDoctorMail: `Orthopädische Einlagenversorgung für Sicherheitsschuhe für ${data!.forename + ' ' + data!.name + ', geb. ' + data!.birthday}`, 
            bodyDoctorMail: `Sehr geehrte:r ${data!.doctorTitle + ' ' + data!.doctorForename + ' ' + data!.doctorName},

Ihr Patient ${data!.forename + ' ' + data!.name} hat Fußprobleme und wird deshalb durch uns mit Einlagen versorgt.

Neben Einlagen für den privaten Bereich, benötigt Herr/Frau ${data!.name} auch Einlagen in Arbeitsschutzschuhe. Diese Einlagen werden nicht von der Krankenkasse, sondern vom Rententräger finanziert. 

Wir bitten Sie, im Auftrag des Patienten zur Beantragung die Formulare S0051 (Befundbericht) und S0050 (Honorarabrechnung) auszufüllen. 
Dafür erhalten Sie vom Rententräger eine Vergütung von 35 EUR.

Wir empfehlen, dass wir alle Dokumente komplett beim Rententräger einreichen, auch Ihre Honorarabrechnung. Somit bekommt der Bearbeiter alle Dokumente zusammen auf den Tisch und die Liquidation ist gesichert. Bitte senden Sie beide Formulare per Post an uns zurück.

Sie erhalten von uns per E-Mail eine Bestätigung über den Versand des Vorgangs, bei evt. Zahlungsverzögerungen können Sie sich auf das Versanddatum berufen. 
`,
            subject: 'Ihr Antrag beim Rententräger wurde eingereicht',
            body: `Sehr geehrte:r ${data!.forename + ' ' + data!.name},

heute haben wir alle Unterlagen für den Antrag über Einlagen in Arbeitsschutzschuhe beim Rententräger zusammen und diesen sofort abgeschickt. 
Sobald Sie einen Bescheid erhalten, egal welchen Inhalts, benachrichtigen sie uns bitte, denn wir erhalten keinerlei Post vom Rententräger.
`,
            subjectDoctorMail2: `Antrag eingereicht für ${data!.forename + ' ' + data!.name + ', geb. ' + data!.birthday}`,
            bodyDoctorMail2: `Sehr geehrte:r ${data!.doctorTitle + ' ' + data!.doctorForename + ' ' + data!.doctorName},

heute haben wir den Antrag über Einlagen in Arbeitsschutzschuhe beim Rententräger eingereicht.
`
        }


        return(
            <form className={styles.root}>
                <h1>{this.state.new === true ? 'Neuer Arbeitsschutz-Antrag' : 'Arbeitsschutz-Antrag'}</h1>

                <div className={styles.step + ' ' + (Object.keys(data!).length > 2 ? styles.completed : '')}>

                    {this.CheckorUncheck('Datensatz des Kunden erfassen',Object.keys(data!).length,2,true)}

                    <div className={styles.content}>
                        <div className={styles.row}>
                            <DefaultButton text="Datensatz öffnen" onClick={() => this.setState({showModal: true})} />
                        </div>
                    </div>
                </div>

                <Modal
                    isOpen={this.state.showModal}
                    onDismiss={() => this.setState({showModal: false})}
                    isBlocking={true}
                >
                    <hr className={styles.blocker} />
                    <RouteComponent component={DataForm} data={this.state.item.data} customer={this.props.users} onChange={(key: keyof ApplicationState['item']['data'], value: any) => this.changeData(key, value)} onSave={() => {
                        this.setState({showModal: false}, this.props.onSave(this.state.item))}} items={this.props.items} config={this.props.config} user={this.props.user} users={this.props.users}/>
                </Modal>

                <div className={styles.step + ' ' + (email === 'complete' ? styles.completed : '')}>
                 {this.CheckorUncheck('Unterlagen zusenden',email,'complete')}
                    <div className={styles.content}>
                        {email !== '' &&
                        <>
                        <p>Folgende Unterlagen können dem Kunde zugesendet werden. Benutzen Sie dafür die <a href={this.generateMailtoLink(data!.email, templates.subjectFirstMail, templates.bodyFirstMail)}>E-Mail-Vorlage</a>.</p>
                        <p>
                            <strong>G0134 Notwendigkeitsbescheinigung des Arbeitgebers</strong><br />
                            <a href="/api/document/G0134.pdf" target="_blank" rel={'noreferrer'}>Formular</a> |
                            <a href={'/api/document/G0134.xfdf?id=' + id} target="_blank" rel={'noreferrer'}>Inhaltsdaten</a>
                            | <a href={'/api/document/G0134.pdf?id=' + id + '&mode=true'} target="_blank" rel={'noreferrer'}>ausgefülltes Formular</a>
                        </p>
                        <p>
                            <strong>Rezeptvorlage für den Orthopäden</strong><br />
                            <a href="/api/document/rezept-bettungseinlage.pdf" target="_blank" rel={'noreferrer'}>Formular</a> | <a rel={'noreferrer'} href={"/api/document/rezept-bettungseinlage.xfdf?id=" + id} target="_blank">Inhaltsdaten</a>
                            | <a href={'/api/document/rezept-bettungseinlage.pdf?id=' + id + '&mode=true'} rel={'noreferrer'} target="_blank">ausgefülltes Formular</a>
                        </p>
                        <p>
                            <strong>Managementauftrag ggü. Rententräger</strong><br />
                            <a href="/api/document/managementauftrag-rententraeger.pdf" target="_blank" rel={'noreferrer'}>Formular</a> | <a rel={'noreferrer'} href={"/api/document/managementauftrag-rententraeger.xfdf?id=" + id} target="_blank">Inhaltsdaten</a>
                            | <a href={'/api/document/managementauftrag-rententraeger.pdf?id=' + id + '&mode=true'} rel={'noreferrer'} target="_blank">ausgefülltes Formular</a>
                        </p>
                        <p>
                            <strong>Managementauftrag ggü. Arzt</strong><br />
                            <a href="/api/document/managementauftrag-arzt.pdf" target="_blank" rel={'noreferrer'}>Formular</a> | <a rel={'noreferrer'} href={"/api/document/managementauftrag-arzt.xfdf?id="+ id} target="_blank">Inhaltsdaten</a>
                            | <a href={'/api/document/managementauftrag-arzt.pdf?id=' + id + '&mode=true'} rel={'noreferrer'} target="_blank">ausgefülltes Formular</a>
                        </p>
                        <p><a href={'/api/archive/first.zip?id=' + id} target="_blank" rel={'noreferrer'}>Alle Dateien downloaden</a></p>
                        </>
                        }

                        {email === 'pending' &&
                        <div className={styles.row}>
                            <PrimaryButton text="E-Mail gesendet" onClick={() => this.changeStep('email', 'complete', 'forms', 'pending')} />
                        </div>
                        }
                    </div>
                </div>

                <div className={styles.step + ' ' + (forms === 'complete' ? styles.completed : '')}>
                    {this.CheckorUncheck('Vor-Ort-Termin mit dem Kunde',forms,'complete')}
                    <div className={styles.content}>
                        {forms !== '' &&
                        <>
                        <p>Vereinbaren Sie bitte einen Termin zur Unterzeichnung der Anträge und Versorgung des Kunden.</p>
                        <p>
                            <strong>G0100 Antrag auf Leistungen zur Teilhabe für Versicherte</strong><br />
                            <a href="/api/document/G0100.pdf" target="_blank" rel={'noreferrer'}>Formular</a> | <a rel={'noreferrer'} href={"/api/document/G0100.xfdf?id=" + id} target="_blank">Inhaltsdaten</a>
                            | <a href={'/api/document/G0100.pdf?id=' + id + '&mode=true'} rel={'noreferrer'} target="_blank">ausgefülltes Formular</a>
                        </p>
                        <p>
                            <strong>G0130 Berufliche Rehabilitation</strong><br />
                            <a href="/api/document/G0130.pdf" target="_blank" rel={'noreferrer'}>Formular</a> | <a rel={'noreferrer'} href={"/api/document/G0130.xfdf?id=" + id} target="_blank">Inhaltsdaten</a>
                            | <a href={'/api/document/G0130.pdf?id=' + id + '&mode=true'} rel={'noreferrer'} target="_blank">ausgefülltes Formular</a>
                        </p>
                        <p>
                            <strong>G0133 Kostenübernahme für Hilfsmittel</strong><br />
                            <a href="/api/document/G0133.pdf" target="_blank" rel={'noreferrer'}>Formular</a> | <a rel={'noreferrer'} href={"/api/document/G0133.xfdf?id=" + id} target="_blank">Inhaltsdaten</a>
                            | <a href={'/api/document/G0133.pdf?id=' + id + '&mode=true'} rel={'noreferrer'} target="_blank">ausgefülltes Formular</a>
                        </p>
                        <p><a href={'/api/archive/second.zip?id=' + id} rel={'noreferrer'} target="_blank">Alle Dateien downloaden</a></p>
                        </>
                        }

                        {forms === 'pending' &&
                        <div className={styles.row}>
                            <PrimaryButton text="Unterlagen vollständig" onClick={() => this.changeStep('forms', 'complete', 'doctor', 'pending')} />
                        </div>
                        }
                    </div>
                </div>

                <div className={styles.step + ' ' + (doctor === 'complete' ? styles.completed : '')}>
                    {this.CheckorUncheck('Ärztlichen Befund einholen',doctor,'complete')}
                    <div className={styles.content}>
                        {doctor !== '' &&
                        <>
                        <p>Senden Sie folgende Unterlagen an den zuständigen Arzt. Sie können dafür die <a href={this.generateMailtoLink(data!.doctorEmail, templates.subjectDoctorMail, templates.bodyDoctorMail)}>E-Mail-Vorlage</a> verwenden, falls die E-Mail-Adresse des Arztes vorliegt.</p>
                        <p>
                            <strong>Anschreiben für den Arzt</strong><br />
                            <a href="/api/document/anschreiben-arzt.pdf" rel={'noreferrer'} target="_blank">Formular</a> | <a rel={'noreferrer'} href={"/api/document/anschreiben-arzt.xfdf?id=" + id} target="_blank">Inhaltsdaten</a>
                            | <a href={'/api/document/anschreiben-arzt.pdf?id=' + id + '&mode=true'} rel={'noreferrer'} target="_blank">ausgefülltes Formular</a>
                        </p>
                        <p>
                            <strong>S0051 Ärtzlicher Befundbericht</strong><br />
                            <a href="/api/document/S0051.pdf" target="_blank" rel={'noreferrer'}>Formular</a> | <a rel={'noreferrer'} href={"/api/document/S0051.xfdf?id=" + id} target="_blank">Inhaltsdaten</a>
                            | <a href={'/api/document/S0051.pdf?id=' + id + '&mode=true'} rel={'noreferrer'} target="_blank">ausgefülltes Formular</a>
                        </p>
                        <p>
                            <strong>S0050 Honorarabrechnung</strong><br />
                            <a href="/api/document/S0050.pdf" target="_blank" rel={'noreferrer'}>Formular</a> | <a rel={'noreferrer'} href={"/api/document/S0050.xfdf?id=" + id} target="_blank">Inhaltsdaten</a>
                            | <a href={'/api/document/S0050.pdf?id=' + id + '&mode=true'} rel={'noreferrer'} target="_blank">ausgefülltes Formular</a>
                        </p>
                        <p><a href={'/api/archive/third.zip?id=' + id} rel={'noreferrer'} target="_blank">Alle Dateien downloaden</a></p>
                        </>
                        }

                        {doctor === 'pending' &&
                        <div className={styles.row}>
                            <PrimaryButton text="Befund erhalten" onClick={() => this.changeStep('doctor', 'complete', 'status', 'pending')} />
                        </div>
                        }
                    </div>
                </div>

                <div className={styles.step + ' ' + (status === 'granted' ? styles.completed : '')}>
                    {this.CheckorUncheck('Antrag einreichen',status,'granted')}
                    <div className={styles.content}>
                        {status === 'pending' && 
                        <>
                        <p>Alle Dokumente liegen jetzt vor. Sie können den Antrag einreichen und den Kunde darüber <a href={this.generateMailtoLink(data!.email, templates.subject, templates.body)}>informieren</a>.</p>
                        <p>Bitte informieren ebenfalls den zuständigen Arzt über den Einreichungszeitpunkt mittels folgender <a href={this.generateMailtoLink(data!.doctorEmail, templates.subjectDoctorMail2, templates.bodyDoctorMail2)}>E-Mail-Vorlage</a>.</p>
                        <div className={styles.row}>
                            <PrimaryButton text="Antrag bewilligt" onClick={(e) => this.changeStep('status', 'granted')} />
                            <DefaultButton text="Antrag abgelehnt" onClick={(e) => this.changeStep('status', 'rejected')} />
                        </div>
                        </>
                        }
                    </div>
                </div>

                <div className={styles.footer}>
                    <PrimaryButton text="Speichern" onClick={(e) => {
                        this.props.onSave(this.state.item);
                        navigate(-1);
                    }} disabled={invalidInput} />
                    <DefaultButton text="Abbrechen" onClick={(e) => navigate(-1)} />

                    <PrimaryButton text="Folgeantrag generieren" href={'/api/archive/follow-up_application.zip?id='+ id+'&uid='+ customer+''} disabled={invalidInput} style={{float:'right'}} />
                </div>
            </form>
        )
    }

    _selectItem(){
        const {id} = this.props.router.params;
        const items = this.props.items;

        if(typeof id !== 'undefined'){
            let selectedItem = items.filter(item => item.id === parseInt(id));
            if(selectedItem.length > 0){
                this.setState({
                    new: false,
                    item: selectedItem[0]
                });
            }
        }
    }
 

    changeData(key: string, value: any){
        const copy: any = {...this.state.item};
        copy.data![key] = value;
        copy.email = 'pending';
        this.setState({item: copy});
    }

    changeStep(name: string, value: any, name2: string = '', value2: any = ''){
        const copy: any = {...this.state.item};
        copy[name] = value;
        if(name2 !== ''){
            copy[name2] = value2;
        }
        this.setState({ item: copy });
    }

    generateMailtoLink(to: string | number | boolean, subject: string | number | boolean, body: string | number | boolean){

        return 'mailto:' + encodeURIComponent(to) + '?subject=' + encodeURIComponent(subject) + '&body=' + encodeURIComponent(body);
    }

    generateMailToAttachmentLink(){

    }

    saveAction(back = false){
        this.props.onSave(this.state.item);
        if(this.state.new === true){
            if(back === true){
                this.props.router.navigate(-1);
            }
        }
    }
}

export default Application;
