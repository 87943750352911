import React, {Component} from 'react';

import queryString from 'query-string';
import {BrowserRouter as Router, Routes, Route, Link} from "react-router-dom";
import {
    Fabric,
    loadTheme,
    initializeIcons,
    FontIcon,
    Dialog,
    DialogFooter,
    PrimaryButton,
    IconButton,
    IColumn, DefaultButton
} from '@fluentui/react';

import NavWithRouter, {RouteComponent} from './core/parts/NavWithRouter';
import LoginForm from './modules/Login/Pages/LoginForm';
import Dashboard from './modules/Dashbaord/Pages/Dashboard';
import Users from './modules/User/Pages/Users';
import User, {RequirementsType} from './modules/User/Pages/User';
import UserOSZL from './modules/User/Pages/User-oszl';
import Recipe from './modules/Recipe/Pages/Recipe';
import Recipes from './modules/Recipe/Pages/Recipes';
import Survey from './modules/Survey/Pages/Survey';
import Surveys from './modules/Survey/Pages/Surveys';
import Ticket, {TicketInterface} from './modules/Ticket/Pages/Ticket';
import Tickets from './modules/Ticket/Pages/Tickets';
import Application from './modules/Application/Pages/Application';
import DataForm from './modules/ApplicationData/Pages/ApplicationData';
import Applications from './modules/Application/Pages/Applications';
import Registration from './modules/Registration/Pages/Registration';
import RegistrationOSZL from './modules/Registration/Pages/Registration-oszl';
import Statistics from './modules/Statistics/Pages/Statistics';
import Settings from './core/Settings';
import Page from './modules/Page/Pages/Page';
import Config from './core/Config';
import styles from './core/css/App.module.css';


const _ = {
    merge: require('lodash/merge')
};

export class ListSorter {
    setColumn(column: IColumn, sort: { field?: string, desc?: boolean }) {

        let feld = typeof (column.fieldName) !== 'undefined' ? column.fieldName : column.name;
        column.isSorted = sort?.field === feld;
        column.isSortedDescending = sort?.desc;
        return column;
    }

    sortItems(items: { sort: (callback: (first: any, second: any) => any) => any }, columns: { filter: (callback: (item: any) => boolean) => any, onRender?: (item: any, column: any) => number | string }, field: any, dir: any) {
        return items.sort((a, b) => {
            let C = columns.filter(c => {
                // console.debug(c);
                if (typeof (c.fieldName) !== 'undefined') {
                    return c.fieldName === field;
                } else {
                    return c.name === field;
                }
            });
            if (C.length > 0) {
                let column = C[0], afield = null, bfield = null;
                if (typeof (column.onRender) === "function") {
                    afield = column.onRender(a, column);
                    bfield = column.onRender(b, column);
                } else {
                    afield = a[typeof (column.fieldName) !== 'undefined' ? column.fieldName : column.name];
                    bfield = b[typeof (column.fieldName) !== 'undefined' ? column.fieldName : column.name];
                }
                if (afield === null || bfield === null) {
                    return 0;
                } else {
                    return (afield > bfield ? 1 : afield < bfield ? -1 : 0) * (dir === 'desc' ? -1 : 1);
                }

            }
            return 0;
        });
    }

    storeSort(view: string, sort: any) {
        let sortings: any = {};
        if (typeof (sessionStorage['mdrSorting']) !== 'undefined') {
            sortings = JSON.parse(sessionStorage['mdrSorting']);
        }
        sortings[view] = sort;
        sessionStorage['mdrSorting'] = JSON.stringify(sortings);
    }

    getSort(view: string, defaultSort: any) {
        let sortings: any = {};
        if (typeof (sessionStorage['mdrSorting']) !== 'undefined') {
            sortings = JSON.parse(sessionStorage['mdrSorting']);
        }
        if (typeof (sortings[view]) !== 'undefined') {
            return sortings[view];
        } else {
            return defaultSort;
        }
    }
}

interface AppProps {
}

export type UserType = Exclude<AppState['loggedIn'], null>['user'];

export interface AppState {
    config: null | {
        faq: any,
        privacyReduced: any,
        privacy: any,
        imprint: any
    },
    loggedIn: null | {
        user: {
            id?: string | number,
            location: string | number,
            usergroup: string,
            firstname: string,
            lastname: string,
            password: string,
            requirements: RequirementsType,
            regularCustomer: boolean,
            title: string,
            email: string,
            phone: string,
            mobile: string,
            birthday: string,
            description: string,
            street: string,
            addition: string,
            zip: string,
            city: string,
            country: string,
            healthInsurance: string,
            healthInsuranceID: string,
            careLevel: string,
            consents: any,
            consentsChanged: boolean,
        },
        token: {
            token: any
        }
    },
    filiale: null | Exclude<AppState['loggedIn'], null>,
    token: any,
    message: null | {
        title: any,
        body: any,
    },
    users: UserType[],
    recipes: any[],
    tickets: Array<TicketInterface>,
    surveys: any[],
    settings: any,
    applications: any[],
    showNav?: boolean,
    showLogin?: boolean
}


class App extends Component<AppProps, AppState> {


    config: typeof Config;
    state: AppState;
    ListSorter: ListSorter;


    constructor(props: AppProps) {
        super(props);

        this.config = Config;

        this.state = {
            config: null,
            loggedIn: null,
            filiale: null,
            token: null,
            message: null,
            users: [],
            recipes: [],
            tickets: [],
            surveys: [],
            settings: {},
            applications: [],
            showNav: true,

        };
        this.ListSorter = new ListSorter();
    }

    componentDidMount() {
        this._loadConfiguration();
        initializeIcons();

        if (typeof (localStorage.showNav) !== 'undefined') {
            this.setState({showNav: localStorage.showNav === 'true'})
        } else if (window.innerWidth < 1024) {
            this.setState({showNav: false})
        }

        this._loadConfigFile().then(result => {

            loadTheme(this.config.fluentTheme);

            const GET = queryString.parse(window.location.search);
            if (typeof (GET.token) === 'string') {
                this.setState({token: GET.token}, () => this.refreshLogin());
            }
        });

        if (localStorage.PeseoUser) {
            const lastuser = JSON.parse(localStorage.PeseoUser);

            if (lastuser.token.expires && ((lastuser.token.expires * 1000) > Date.now())) {
                this.setState({
                    loggedIn: lastuser
                }, () => this.loadDataInital());
            } else {
                localStorage.removeItem('PeseoUser');
            }
        } else if (localStorage.PeseoLocation) {
            const lastLocation = JSON.parse(localStorage.PeseoLocation);

            if (lastLocation.token.expires && ((lastLocation.token.expires * 1000) > Date.now())) {
                this.setState({
                    filiale: lastLocation
                });
            } else {
                localStorage.removeItem('PeseoLocation');
            }
        }

    }


    render(): React.ReactNode {


        const usergroup = this.state.loggedIn?.user.usergroup;
        const priv = ['system', 'admin', 'employee'];
        const ticketCount = this.state.tickets.filter(ticket => ticket.status === 'open').length;
        const ListSorterObj = this.ListSorter;

        const AppComponent = (props: any) => {
            if ((this.state.loggedIn !== null || this.state.filiale !== null) && this.state.showLogin !== true) {
                return (
                    <>
                        {this.renderHeader()}
                        <main
                            className={(typeof usergroup !== 'undefined' && priv.indexOf(usergroup) !== -1 ? styles.adminView : '')}>
                            <div
                                className={styles.leftbar + ' ' + (this.state.showNav ? styles.showNav : styles.hideNav)} style={(this.state.filiale !== null && this.state.loggedIn === null ? {display:'none'} : {})}>
                                <div className={styles.leftbarContent}>
                                    <NavWithRouter navLinks={this.config.navLinks}/>
                                    <div className={styles.leftbarDonwContent}>
                                        <IconButton
                                            iconProps={{iconName: this.state.showNav ? 'ChevronLeft' : 'ChevronRight'}}
                                            onClick={() => {
                                                this.setState({showNav: !this.state.showNav}, () => {
                                                    localStorage.showNav = this.state.showNav
                                                })
                                            }}/>
                                    </div>
                                </div>
                            </div>

                            {props.element}

                            {this.config.showFAQ === true ? <Link className={styles.floatingButton} to="/faq"><FontIcon
                                iconName="StatusCircleQuestionMark" className={styles.icon}/></Link> : null}
                            <Dialog hidden={this.state.message === null}
                                    onDismiss={() => this.setState({message: null})} title={this.state.message?.title}>
                                <p>{this.state.message?.body}</p>
                                <DialogFooter>
                                    <PrimaryButton onClick={() => this.setState({message: null})} text="OK"/>
                                </DialogFooter>
                            </Dialog>
                        </main>
                    </>);
            } else {
                return (
                    <LoginForm config={this.config} filiale={this.state.filiale !== null}
                               onLogin={(email, password, name, date) => this.handleLogin(email, password, name, date)}/>
                );
            }
        }

        return (
            <Fabric>
                <Router>
                    <Routes>
                        <Route path="/faq" element={
                            <>
                                {this.renderHeader()}
                                <Page content={{__html: this.state.config?.faq}}/>
                            </>
                        }/>
                        <Route path="/datenschutz" element={
                            <>
                                {this.renderHeader()}
                                <Page content={{__html: this.state.config?.privacyReduced}}/>
                            </>
                        }/>
                        <Route path="/datenschutz-intern" element={
                            <>
                                {this.renderHeader()}
                                <Page content={{__html: this.state.config?.privacy}}/>
                            </>
                        }/>
                        <Route path="/impressum" element={
                            <>
                                {this.renderHeader()}
                                <Page content={{__html: this.state.config?.imprint}}/>
                            </>
                        }/>
                        <Route path="/">
                            <Route path="users/:id" element={
                                <AppComponent element={
                                    this.config.company === "Orthopädie-Schuh-Zentrum Leipzig GmbH"
                                        ? <RouteComponent component={UserOSZL} config={this.config}
                                                          user={(this.state.loggedIn !== null ? this.state.loggedIn!.user : null)}
                                                          items={this.state.users}
                                                          onSave={(user: UserType) => this.saveItem('users', user)}/>
                                        : <RouteComponent component={User}
                                                          config={this.config}
                                                          user={(this.state.loggedIn !== null ? this.state.loggedIn!.user : null)}
                                                          items={this.state.users}
                                                          onSave={(user: UserType) =>
                                                              this.saveItem('users', user)}/>
                                }/>}/>
                            <Route path="users/" element={
                                <AppComponent element={
                                    <RouteComponent component={Users} config={this.config}
                                                    user={(this.state.loggedIn !== null ? this.state.loggedIn!.user : null)}
                                                    items={this.state.users} onRefresh={() => this.loadItems('users')}
                                                    ListSorter={ListSorterObj}/>
                                }/>}/>
                            <Route path="recipes/:id" element={
                                <AppComponent element={
                                    <RouteComponent component={Recipe} config={this.config}
                                                    user={(this.state.loggedIn !== null ? this.state.loggedIn!.user : null)}
                                                    settings={this.state.settings} items={this.state.recipes}
                                                    onSave={(item: any) => this.saveItem('recipes', item)}
                                                    users={this.state.users}/>
                                }/>}/>
                            <Route path="recipes" element={
                                <AppComponent element={
                                    <RouteComponent component={Recipes} config={this.config}
                                                    user={(this.state.loggedIn !== null ? this.state.loggedIn!.user : null)}
                                                    items={this.state.recipes}
                                                    onRefresh={() => this.loadItems('recipes')}
                                                    onSave={(item) => this.saveItem('recipes', item)}
                                                    users={this.state.users} ListSorter={ListSorterObj}/>
                                }/>}/>
                            <Route path="surveys/:id" element={
                                <AppComponent element={
                                    <RouteComponent component={Survey} config={this.config}
                                                    user={(this.state.loggedIn !== null ? this.state.loggedIn!.user : null)}
                                                    items={this.state.surveys}
                                                    onSave={(type, item, message) => this.saveItem(type, item, message)}/>
                                }/>}/>
                            <Route path="surveys" element={
                                <AppComponent element={
                                    <RouteComponent component={Surveys}
                                                    config={this.config}
                                                    user={(this.state.loggedIn !== null ? this.state.loggedIn!.user : null)}
                                                    items={this.state.surveys}
                                                    onRefresh={() => this.loadItems('surveys')}
                                                    users={this.state.users} recipes={this.state.recipes}
                                                    ListSorter={ListSorterObj}
                                    />
                                }/>}/>
                            <Route path="tickets/:id" element={
                                <AppComponent element={
                                    <RouteComponent component={Ticket} config={this.config}
                                                    user={(this.state.loggedIn !== null ? this.state.loggedIn!.user : null)}
                                                    items={this.state.tickets}
                                                    onSave={(item: any) => this.saveItem('tickets', item)}
                                                    users={this.state.users}/>
                                }/>}/>
                            <Route path="tickets" element={
                                <AppComponent element={
                                    <RouteComponent component={Tickets} config={this.config}
                                                    user={(this.state.loggedIn !== null ? this.state.loggedIn!.user : null)}
                                                    items={this.state.tickets}
                                                    onRefresh={() => this.loadItems('tickets')} users={this.state.users}
                                                    ListSorter={ListSorterObj}/>
                                }/>}/>
                            <Route path="registration" element={
                                <AppComponent element={
                                    this.config.company === "Orthopädie-Schuh-Zentrum Leipzig GmbH"
                                        ? <RouteComponent component={RegistrationOSZL} config={this.config}
                                                          activeLocation={this.state.filiale}
                                                          onSave={(item) => this.saveItem('users', item, {
                                                              title: 'Aktion erfolgreich',
                                                              body: 'Benutzer wurde gespeichert.'
                                                          })}/>
                                        : <RouteComponent component={Registration} config={this.config}
                                                          activeLocation={this.state.filiale}
                                                          onSave={(item) => this.saveItem('users', item, {
                                                              title: 'Aktion erfolgreich',
                                                              body: 'Benutzer wurde gespeichert.'
                                                          })}/>
                                }/>}/>
                            <Route path="statistics" element={
                                <AppComponent element={
                                    <RouteComponent component={Statistics} config={this.config}
                                                    user={(this.state.loggedIn !== null ? this.state.loggedIn!.user : null)}
                                                    recipes={this.state.recipes} users={this.state.users}/>
                                }/>}/>
                            <Route path="settings" element={
                                <AppComponent element={
                                    <RouteComponent component={Settings} config={this.config}
                                                    user={(this.state.loggedIn !== null ? this.state.loggedIn!.user : null)}
                                                    settings={this.state.settings}
                                                    onSettingsChange={(name, value) => this._changeSettings(name, value)}
                                                    onSave={settings => this._saveSettings(settings)}/>
                                }/>}/>
                            <Route path="applications/data/:id" element={
                                <AppComponent element={
                                    <RouteComponent component={DataForm} config={this.config}
                                                    user={(this.state.loggedIn !== null ? this.state.loggedIn!.user : null)}
                                                    items={this.state.applications}
                                                    onSave={(item) => this.saveItem('applications', item)}
                                                    users={this.state.users}/>
                                }/>}/>
                            <Route path="applications/:id" element={
                                <AppComponent element={
                                    <RouteComponent component={Application} config={this.config}
                                                    user={(this.state.loggedIn !== null ? this.state.loggedIn!.user : null)}
                                                    items={this.state.applications}
                                                    onSave={(item: any) => this.saveItem('applications', item)}
                                                    users={this.state.users}/>
                                }/>}/>
                            <Route path="applications" element={
                                <AppComponent element={
                                    <RouteComponent component={Applications} config={this.config}
                                                    user={(this.state.loggedIn !== null ? this.state.loggedIn!.user : null)}
                                                    items={this.state.applications}
                                                    onRefresh={() => this.loadItems('applications')}
                                                    users={this.state.users} ListSorter={ListSorterObj}/>
                                }/>}/>
                            <Route index element={
                                <AppComponent element={
                                    <Dashboard config={this.config}
                                               user={(this.state.loggedIn !== null ? this.state.loggedIn!.user : null) || 'filiale'}
                                               ticketCount={ticketCount}
                                               onLocationModeClick={location => this.activateLocationMode(location)}
                                               onActivateLogin={() => this.handleActivateLogin()}/>
                                }/>}/>
                        </Route>
                    </Routes>
                </Router>
            </Fabric>

        );
    }

  renderHeader(){
    return(
      <header className={styles.header + ' app-header'}>
        <span className={styles.left}><img className={styles.logo} src="/data/logo.png" alt={this.config.company} /></span>
        {this.state.loggedIn || this.state.filiale ? <span className={styles.right} onClick={(e) => this.handleLogout(e)}>
            <Link to="/">
            <DefaultButton text="Abmelden" />

            </Link>

        </span> : <span>&nbsp;</span>}
      </header>
    );
  }

    _loadConfiguration() {
        this._get(this.config.apiURL + '/config').then(result => {
            this.setState({config: result});
        }).catch(error => console.error(error));
    }

    _loadConfigFile() {
        return this._get('/data/config.json').then(projectConfig => {
            _.merge(this.config, projectConfig);

            if (projectConfig.applications === true) {
                this.config.navLinks[0].links.push(
                    {
                        name: 'Arbeitsschutz',
                        url: '/applications',
                        icon: 'Work',
                        key: '/applications'
                    }
                )
            }
        }).catch(error => {
            console.error(error);
        });
    }

    loadDataInital() {
        this.loadItems('users');
        this.loadItems('recipes');
        this.loadItems('tickets');
        this.loadItems('surveys');
        this.loadItems('applications');
        this._loadSettings();
    }

    loadItems(type: string) {
        this._get(this.config.apiURL + '/' + type).then(result => {
            const stateObject: any = {};
            stateObject[type] = result._embedded;
            this.setState(stateObject);
        }).catch(error => console.error(error));
    }

    saveItem(type: string, item: UserType, message: '' | { title: string, body: string } = '') {
        if (typeof item.id !== 'undefined' && item.id !== '') {
            this._put(this.config.apiURL + '/' + type + '/' + item.id, item).then(result => {
                if (result.status === 'error') {
                    message = {
                        title: 'Aktion fehlgeschlagen',
                        body: 'Bitte wenden Sie sich an den Support. Fehler: ' + result.message
                    }
                } else {
                    this.loadItems(type)
                }
            }).then(() => {
                if (message !== '') {
                    this.setState({message: message});
                }
            });
        } else {
            this._post(this.config.apiURL + '/' + type, item).then(result => {
                if (result.status === 'error') {
                    message = {
                        title: 'Aktion fehlgeschlagen',
                        body: 'Bitte wenden Sie sich an den Support. Fehler: ' + result.message
                    }
                } else {
                    this.loadItems(type)
                }
            }).then(() => {
                if (message !== '') {
                    this.setState({message: message});
                }
            });
        }
    }

    _loadSettings() {
        this._get(this.config.apiURL + '/settings').then(result => {
            this.setState({settings: result});
        }).catch(error => console.error(error));
    }


    _changeSettings(name: string, value: any) {
        let copy = {...this.state.settings};
        copy[name] = value;
        this.setState({settings: copy});
    }

    _saveSettings(settings: any) {
        this._put(this.config.apiURL + '/settings', settings).then(result => {
            this._loadSettings();
        }).catch(error => console.error(error));
    }

    activateLocationMode(location: UserType['location']) {
        const copy = this.state.loggedIn;

        if (typeof location !== 'undefined') {
            if (copy !== null) {
                copy.user.location = location;
            }
        }
        localStorage.setItem('PeseoLocation', JSON.stringify(copy));
        localStorage.removeItem('PeseoUser');

        this.setState({
            filiale: copy,
            loggedIn: null
        })
    }

    handleActivateLogin() {
        this.setState({
            showLogin: true,
        })
    }

    _getAuth() {
        let value = '';

        if (this.state.token) {
            value = 'Bearer ' + this.state.token;
        }

        if (this.state.filiale) {
            value = 'Bearer ' + this.state.filiale.token.token;
        }

        if (this.state.loggedIn) {
            value = 'Bearer ' + this.state.loggedIn.token.token;
        }

        return value;
    }

    _get(url: string | URL) {

        return fetch(url, {
            //cache: 'cache',
            headers: {
                'Authorization': this._getAuth()
            },
            method: 'GET',
            mode: 'cors'
        }).then(response => response.json())
    }

    _post(url: string | URL, data: any) {
        return fetch(url, {
            body: JSON.stringify(data),
            cache: 'no-cache',
            headers: {
                'content-type': 'application/json',
                'Authorization': this._getAuth()
            },
            method: 'POST',
            mode: 'cors'
        }).then(response => response.json())
    }

    _put(url: string | URL, data: any) {
        return fetch(url, {
            body: JSON.stringify(data),
            cache: 'no-cache',
            headers: {
                'content-type': 'application/json',
                'Authorization': this._getAuth()
            },
            method: 'PUT',
            mode: 'cors'
        }).then(response => response.json())
    }

    handleLogin(email: string, password: string, name: string, date: string) {
        const data = (name !== '' && date !== '') ? {name: name, date: date} : {email: email, password: password};

        this._post(this.config.apiURL + '/auth', data).then((result) => {
            if (result.token) {
                localStorage.setItem('PeseoUser', JSON.stringify(result));
                localStorage.removeItem('PeseoLocation');

                this.setState({

                    loggedIn: result,
                    showLogin: false,
                }, () => this.loadDataInital());
            }
        }).catch((error) => {
            console.error(error);
        })
    }

    refreshLogin() {
        this._get(this.config.apiURL + '/token').then((result) => {
            if (result.token) {
                localStorage.setItem('PeseoUser', JSON.stringify(result));

                const GET = queryString.parse(window.location.search);
                if (typeof (GET.token) === 'string') {
                    window.location.href = (window.location.href.replace('?token=' + GET.token, ''));
                }

                this.setState({
                    loggedIn: result,
                    showLogin: false,
                }, () => this.loadDataInital());
            }
        }).catch((error) => {
            console.error(error);
        })
    }

    handleLogout(e: any) {
        localStorage.removeItem('PeseoUser');
        localStorage.removeItem('PeseoLocation');

        this.setState({
            filiale: null,
            loggedIn: null
        });
    }
}

export default App;
